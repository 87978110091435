import React, { useState, useEffect, useRef } from "react";

const COLORS = [
  "#FF1744",
  "#F50057",
  "#FF4081",
  "#2979FF",
  "#00B0FF",
  "#00E5FF",
  "#651FFF",
  "#6200EA",
  "#304FFE",
  "#00E676",
  "#00C853",
  "#64DD17",
  "#FFC400",
  "#FF9100",
  "#FF3D00",
];

const GlowingCircle = ({ delay = 0 }) => {
  return (
    <circle cx="1000" cy="1000" r="0.5">
      <animate
        attributeName="r"
        values="0.5;500"
        dur="2.292s"
        begin={`${delay}s`}
        repeatCount="indefinite"
      />
      <animate
        attributeName="opacity"
        values="1;0"
        dur="2.292s"
        begin={`${delay}s`}
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill"
        values="#FFFF53"
        dur="2.292s"
        begin={`${delay}s`}
        repeatCount="indefinite"
      />
    </circle>
  );
};

const AnimatedBackground = () => {
  const particlesRef = useRef({ large: [], small: [] });
  const requestRef = useRef();
  const canvasRef = useRef(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0,
  });

  const createParticle = (width, height, isLarge = false) => {
    const size = isLarge ? Math.random() * 180 + 120 : Math.random() * 80 + 40;
    return {
      x: Math.random() * width,
      y: Math.random() * height,
      size,
      baseSize: size,
      speedX: (Math.random() - 0.5) * (isLarge ? 0.3 : 0.8),
      speedY: (Math.random() - 0.5) * (isLarge ? 0.3 : 0.8),
      color: COLORS[Math.floor(Math.random() * COLORS.length)],
      targetColor: COLORS[Math.floor(Math.random() * COLORS.length)],
      colorProgress: 0,
      pulseDirection: 1,
      pulseSpeed: Math.random() * 0.02 + 0.01,
      rotationAngle: Math.random() * Math.PI * 2,
      rotationSpeed: (Math.random() - 0.5) * 0.01,
      opacity: isLarge ? Math.random() * 0.3 + 0.2 : Math.random() * 0.5 + 0.3,
    };
  };

  const lerp = (start, end, t) => start * (1 - t) + end * t;

  const lerpColor = (color1, color2, t) => {
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);
    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);

    const r = Math.round(lerp(r1, r2, t));
    const g = Math.round(lerp(g1, g2, t));
    const b = Math.round(lerp(b1, b2, t));

    return `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  };

  useEffect(() => {
    const generateParticles = () => {
      const largeParticles = Array(15)
        .fill(null)
        .map(() =>
          createParticle(canvasDimensions.width, canvasDimensions.height, true)
        );
      const smallParticles = Array(25)
        .fill(null)
        .map(() =>
          createParticle(canvasDimensions.width, canvasDimensions.height, false)
        );
      particlesRef.current = { large: largeParticles, small: smallParticles };
    };

    if (canvasDimensions.width && canvasDimensions.height) {
      generateParticles();
    }
  }, [canvasDimensions]);

  useEffect(() => {
    const drawParticle = (ctx, particle, blur = false) => {
      ctx.save();
      if (blur) {
        ctx.filter = `blur(${8 + Math.sin(particle.pulseDirection) * 2}px)`;
      }

      const currentColor = lerpColor(
        particle.color,
        particle.targetColor,
        particle.colorProgress
      );
      const currentSize =
        particle.baseSize * (1 + Math.sin(particle.pulseDirection) * 0.3);

      ctx.translate(particle.x, particle.y);
      ctx.rotate(particle.rotationAngle);

      const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, currentSize);
      gradient.addColorStop(
        0,
        `${currentColor}${Math.floor((particle.opacity + 0.2) * 255)
          .toString(16)
          .padStart(2, "0")}`
      );
      gradient.addColorStop(
        0.4,
        `${currentColor}${Math.floor((particle.opacity + 0.1) * 255)
          .toString(16)
          .padStart(2, "0")}`
      );
      gradient.addColorStop(
        0.7,
        `${currentColor}${Math.floor(particle.opacity * 0.3 * 255)
          .toString(16)
          .padStart(2, "0")}`
      );
      gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

      ctx.fillStyle = gradient;
      ctx.beginPath();
      ctx.arc(0, 0, currentSize, 0, Math.PI * 2);
      ctx.fill();

      ctx.restore();
    };

    const animate = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);

      particlesRef.current.large.forEach((particle) => {
        drawParticle(ctx, particle, true);
      });

      particlesRef.current.small.forEach((particle) => {
        drawParticle(ctx, particle);
      });

      [...particlesRef.current.large, ...particlesRef.current.small].forEach(
        (particle) => {
          particle.x += particle.speedX;
          particle.y += particle.speedY;
          particle.rotationAngle += particle.rotationSpeed;
          particle.colorProgress += 0.005;

          if (particle.colorProgress >= 1) {
            particle.color = particle.targetColor;
            particle.targetColor =
              COLORS[Math.floor(Math.random() * COLORS.length)];
            particle.colorProgress = 0;
          }

          particle.pulseDirection += particle.pulseSpeed;

          if (particle.x < -particle.size)
            particle.x = canvasDimensions.width + particle.size;
          if (particle.x > canvasDimensions.width + particle.size)
            particle.x = -particle.size;
          if (particle.y < -particle.size)
            particle.y = canvasDimensions.height + particle.size;
          if (particle.y > canvasDimensions.height + particle.size)
            particle.y = -particle.size;
        }
      );

      requestRef.current = requestAnimationFrame(animate);
    };

    if (canvasDimensions.width && canvasDimensions.height) {
      requestRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [canvasDimensions]);

  useEffect(() => {
    const updateDimensions = () => {
      if (canvasRef.current) {
        const { clientWidth, clientHeight } = canvasRef.current.parentElement;
        setCanvasDimensions({
          width: clientWidth,
          height: clientHeight,
        });
        canvasRef.current.width = clientWidth;
        canvasRef.current.height = clientHeight;
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full" />
  );
};

const CombinedAnimation = () => {
  const centerX = 1000;
  const centerY = 1000;
  const radius = 200;
  const starRadius = radius * 1.732;

  const linePoints = [];
  for (let i = 0; i < 6; i++) {
    const angle = (i * Math.PI) / 3 + Math.PI / 6;
    linePoints.push({
      x: centerX + radius * Math.sin(angle),
      y: centerY - radius * Math.cos(angle),
    });
  }

  const starPoints = [];
  for (let i = 0; i < 6; i++) {
    const angle = (i * Math.PI) / 3;
    starPoints.push({
      x: centerX + starRadius * Math.sin(angle),
      y: centerY - starRadius * Math.cos(angle),
    });
  }

  const length = 2000;
  const linePaths = linePoints.map((point, i) => {
    const nextPoint = linePoints[(i + 1) % 6];
    const angle = Math.atan2(nextPoint.y - point.y, nextPoint.x - point.x);

    const startX = point.x - Math.cos(angle) * length;
    const startY = point.y - Math.sin(angle) * length;
    const endX = point.x + Math.cos(angle) * length;
    const endY = point.y + Math.sin(angle) * length;

    return `M ${startX} ${startY} L ${endX} ${endY}`;
  });

  return (
    <div className="relative w-full h-screen bg-black overflow-hidden">
      <AnimatedBackground />
      <div className="absolute inset-0 backdrop-blur-md" />

      {/* First SVG layer for glowing circles */}
      <svg
        className="absolute inset-0 w-full h-full"
        viewBox="0 0 2000 2000"
        preserveAspectRatio="xMidYMid slice"
      >
        <GlowingCircle delay={0} />
        <GlowingCircle delay={0.764} />
        <GlowingCircle delay={1.528} />
      </svg>

      {/* Second SVG layer for hexagram */}
      <svg
        className="absolute inset-0 w-full h-full"
        viewBox="0 0 2000 2000"
        preserveAspectRatio="xMidYMid slice"
      >
        {/* Background lines */}
        {linePaths.map((path, i) => (
          <path
            key={`line-${i}`}
            d={path}
            stroke="rgba(255, 253, 231, 0.7)"
            strokeWidth="38"
            strokeLinejoin="miter"
            fill="none"
          />
        ))}

        {/* Star of David */}
        <path
          d={`M ${starPoints[0].x} ${starPoints[0].y} 
              L ${starPoints[2].x} ${starPoints[2].y} 
              L ${starPoints[4].x} ${starPoints[4].y} Z`}
          stroke="white"
          strokeWidth="38"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d={`M ${starPoints[1].x} ${starPoints[1].y} 
              L ${starPoints[3].x} ${starPoints[3].y} 
              L ${starPoints[5].x} ${starPoints[5].y} Z`}
          stroke="white"
          strokeWidth="38"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    </div>
  );
};

export default CombinedAnimation;
